.invoicing-container {
  table {
    .billing-account-row {
      td.name {
        width: 100%;
      }
      td.action {
        text-align: center;
      }
      td:first-child {
        width: 100%;
      }
    }
    td {
      white-space: nowrap;
      // border: 1px solid #ddd !important;
      padding: 0.5rem;
    }
  }
}
