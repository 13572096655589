.income-report-container {
  .controls {
    display: flex;
    margin-bottom: 2rem;
    .current-month {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-left: none;
      border-right: none;
      padding: 0 1rem;
    }
    .prev-month,
    .next-month {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #ccc;
      background-color: rgba(0, 0, 0, 0.05);
      &:hover {
        color: #000;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  table.income-report {
    width: 100%;
    border-collapse: collapse;
    .percentage {
      &.good {
        color: green;
      }
      &.bad {
        color: red;
      }
    }

    .workspace-name-row {
      td {
        font-weight: bold;
        padding-top: 1rem;
      }
    }

    tbody.detail-rows {
      tr:nth-child(odd) {
        background-color: #f5f5f5;
      }
      tr:nth-child(even) {
        background-color: #fafafa;
      }
      tr:last-child td {
        padding-bottom: 1rem;
      }
    }

    tbody.summary-row {
      &:hover {
        cursor: pointer;
      }
      &:nth-child(odd) tr {
        background-color: #f8f8f8;
      }
    }

    th {
      text-align: left;
      font-size: 18px;
      padding-bottom: 1rem;
      border-bottom: 1px solid #ddd;
    }

    .num {
      text-align: right;
    }

    .percentage {
      padding-left: 1rem;
    }

    .global-summary-row {
      font-size: 18px;
      font-weight: bold;
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
      }
    }

    .detail-row {
      &:hover {
        cursor: pointer;
      }
    }

    .product-id,
    .service-id,
    .billing-account-id {
      font-family: monospace;
      padding: 0.2rem 0;
      color: #b88;
    }

    .product-id {
      padding: 0;
      margin-left: 1rem;
    }

    .service-id {
      padding-bottom: 1rem;
    }

    .toggle-expand {
      &.subtle {
        opacity: 0.3;
        transform: scale(0.8);
      }
    }
  }
}
